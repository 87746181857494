export default {
  name : "Audio",
  props: {
    pAudioUrl: {
      type   : String,
      default: undefined
    },
    pNumberOfSamples: {
      type   : Number,
      default: 30
    },
    pWaveHeight: {
      type   : Number,
      default: 20
    },
    pProgressHeight: {
      type   : Number,
      default: 5
    },
    pColor: {
      type   : String,
      default: "blue"
    }
  },
  data() {
    return {
      audio        : undefined,
      decodedAudio : undefined,
      isPlaying    : false,
      progress     : 0,
      currentTime  : 0,
      totalDuration: 0
    }
  },
  beforeDestroy() {
    this._destroy()
  },
  mounted() {
    try {
      window.AudioContext = window.AudioContext || window.webkitAudioContext
      window.URL          = window.URL || window.webkitURL
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("No web audio support")
    }
  },
  methods: {
    async handleClick() {
      const caller = arguments[0]

      switch (caller) {
        case "button_play_or_pause": {
          this._playOrPauseAudio()
          break
        }
        case "progress_audio": {
          this._calculateAudioCurrentTime()
          break
        }
      }
    },
    _playOrPauseAudio() {
      if (!this.isPlaying) {
        this.audio.play()
      } else {
        this.audio.pause()
      }
      this.isPlaying = !this.isPlaying
    },
    _calculateAudioCurrentTime() {
      this.audio.currentTime = (this.progress * this.totalDuration) / 100
    },
    async _setup() {
      this.audio = new Audio(this.pAudioUrl)
      this.audio.addEventListener("canplay", this._handleCanPlay)
      this.audio.addEventListener("timeupdate", this._handleTimeUpdate)
      this.audio.addEventListener("ended", this._handleEnded)
      await this._setDecodedAudio()
    },
    _destroy() {
      this.audio.pause()
      this.audio.removeEventListener("canplay", this._handleCanPlay)
      this.audio.removeEventListener("timeupdate", this._handleTimeUpdate)
      this.audio.removeEventListener("ended", this._handleEnded)
      this.audio = undefined
    },
    _handleCanPlay() {
      this.totalDuration = this.audio.duration
    },
    _handleTimeUpdate() {
      this.currentTime = this.audio ? this.audio.currentTime : 0
      this.progress    = (this.currentTime / this.totalDuration) * 100
    },
    _handleEnded() {
      this.isPlaying   = false
      this.currentTime = 0
    },
    async _setDecodedAudio() {
      const audioContext = new AudioContext()
      const response     = await fetch(this.pAudioUrl)
      const buffer       = await response.arrayBuffer()
      this.decodedAudio  = await audioContext.decodeAudioData(buffer)
    },
    _extractSamples(decodedAudio, numberOfSamples) {
      const samples     = []
      const channelData = decodedAudio.getChannelData(0)
      const blockSize   = Math.floor(channelData.length / numberOfSamples)

      for (let sampleIterator = 0; sampleIterator < numberOfSamples; sampleIterator++) {
        let sum = 0
        for (let blockIterator = 0; blockIterator < blockSize; blockIterator++) {
          sum += Math.abs(channelData[blockSize * sampleIterator + blockIterator])
        }
        samples.push(sum / blockSize)
      }
      return samples
    },
    _formatSeconds(value) {
      let minutes       = Math.floor(value / 60)
      const seconds     = Math.floor(value % 60)
      const padWithZero = value => `0${value}`.slice(-2)

      minutes %= 60
      return `${padWithZero(minutes)}:${padWithZero(seconds)}`
    }
  },
  computed: {
    samples() {
      if (this.decodedAudio) {
        return this._extractSamples(this.decodedAudio, this.pNumberOfSamples)
      }
    },
    displayTotalDuration() {
      return this._formatSeconds(this.totalDuration)
    },
    displayCurrentTime() {
      return this._formatSeconds(Math.floor(this.currentTime))
    }
  },
  watch: {
    pAudioUrl: {
      immediate: true,
      async handler(newValue) {
        if (newValue) {
          if (this.audio) {
            this._destroy()
          }
          await this._setup()
        }
      }
    }
  }
}