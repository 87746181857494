import {
  SCREEN_ITEM_DISPLAY_PROPERTIES,
  SCREEN_KEY_FIELD_TYPE,
  MAX_CHARACTER_LIMIT,
  WIDGET
} from "@/constants"
import ThemisInput from "@/components/shared/input"
import ThemisDateTimePicker from "@/components/shared/date-time-picker"
import IssueFields from "@/components/issue/fields"
import { ARRAY, SCREEN_ITEM_TYPE } from "../../constants"

export default {
  name: "Screen",
  data: () => ({
    newLabel               : undefined,
    localScreenItems       : [],
    summaryCharacterLimit  : MAX_CHARACTER_LIMIT.ISSUE_SUMMARY,
    descriptionMaxValue    : MAX_CHARACTER_LIMIT.ISSUE_DESCRIPTION_MAX_VALUE,
    showTextAreaCounter    : false,
    isComboboxInputFocussed: false,
    screenItemValuesMap    : new Map(),
    updatedFieldValues     : new Map()
  }),
  components: {
    ThemisInput,
    ThemisDateTimePicker,
    IssueFields
  },
  props: {
    pStaticScreenTitle     : String,
    pDynamicScreenTitle    : String,
    pScreenDescription     : String,
    pScreenItems           : Array,
    pIsTransitionInProgress: Boolean,
    pIsTransitionDone      : Boolean,
    pSubmitButton          : String,
    pIsACase               : Boolean,
    pFormInstances         : Array,
    pCanViewIssueForms     : Boolean
  },
  methods: {
    getScreenItemLabel(screenItem) {
      const label = this.$t(this.getScreenItemName(screenItem))
      return screenItem.mandatory ? `${label}*` : label
    },
    getScreenItemRules(screenItem) {
      const rules = []
      if (screenItem.mandatory) {
        rules.push("required")
      }
      if (screenItem.key === "description") {
        rules.push(`max:${this.descriptionMaxValue}`)
      }
      if (screenItem.key === "summary") {
        rules.push(`max:${this.summaryCharacterLimit}`)
      }
      if (screenItem.key === "labels") {
        rules.push("max:250|min:1")
      }
      return rules.join("|")
    },
    getItemText(screenItem) {
      if (screenItem.key === "assigneeId") {
        return "name"
      } else {
        return "text"
      }
    },
    getItemValue(screenItem) {
      if (screenItem.key === "assigneeId") {
        return "id"
      } else {
        return "value"
      }
    },
    getScreenItemName(screenItem) {
      return SCREEN_ITEM_DISPLAY_PROPERTIES[screenItem.key] ?
        SCREEN_ITEM_DISPLAY_PROPERTIES[screenItem.key].label : screenItem.key
    },
    getScreenItemPlaceholder(screenItem) {
      if (screenItem.key === "summary" || screenItem.key === "description") {
        if (this.pIsACase) {
          return this.$t(SCREEN_ITEM_DISPLAY_PROPERTIES[screenItem.key].placeholderForCase)
        } else {
          return this.$t(SCREEN_ITEM_DISPLAY_PROPERTIES[screenItem.key].placeholderForTask)
        }
      }
      return SCREEN_ITEM_DISPLAY_PROPERTIES[screenItem.key] ?
        this.$t(SCREEN_ITEM_DISPLAY_PROPERTIES[screenItem.key].placeholder) : ""
    },
    onSubmit() {
      const result           = {}
      const issueFieldValues = []
      const issueForms       = []

      for (const item of this.localScreenItems) {
        if (item.screenItemType === SCREEN_ITEM_TYPE.FIELD) {
          const storedIssueFieldValues  = this.screenItemValuesMap.get(item.field.fieldId)
          const currentIssueFieldValues = item.field?.issueFieldValues

          if (currentIssueFieldValues?.length === storedIssueFieldValues?.length) {
            let isValueUpdated = false
            for (const currentIssueFieldValue of currentIssueFieldValues) {
              if (!storedIssueFieldValues.find(storedIssueFieldValue =>
                storedIssueFieldValue.issueFieldId === currentIssueFieldValue.issueFieldId
                && storedIssueFieldValue.value === currentIssueFieldValue.value)
              ) {
                isValueUpdated = true
                break
              }
            }

            if (!isValueUpdated) {
              continue
            }
          }

          issueFieldValues.push(...currentIssueFieldValues.map(field => ({
            issueFieldId: field.issueFieldId,
            value       : field.value
          })))

        } else if(item.type === SCREEN_ITEM_TYPE.FORM) {
          const issueFormsMap = new Map()

          if (item.formFields && item.formFields.length) {
            for (const formField of item.formFields) {
              const formInstance            = this.pFormInstances?.find(formInstance =>
                formInstance.formTemplateId === formField.formTemplateId &&
                formInstance.issueId === +this.$route.params.id && formInstance.id === formField.formInstanceId)
              const formInstanceField       = formInstance?.formInstanceFields.find(formInstanceField =>
                formInstanceField.fieldId === formField.issueForms[ARRAY.FIRST].fieldId
              || formInstanceField.fieldId === formField.fieldId)
              const storedIssueFieldValues  = this.screenItemValuesMap.get(formField.fieldId) || []
              const currentIssueFieldValues = formField.issueForms || []

              if (currentIssueFieldValues.length === storedIssueFieldValues.length) {
                let isValueUpdated = false
                for (const currentIssueFieldValue of currentIssueFieldValues) {
                  if (!storedIssueFieldValues.find(storedIssueFieldValue =>
                    storedIssueFieldValue.issueFieldId === currentIssueFieldValue.issueFieldId
                    && storedIssueFieldValue.value === currentIssueFieldValue.value)
                  ) {
                    isValueUpdated = true
                    break
                  }
                }

                if (!isValueUpdated) {
                  continue
                }
              }
              for (const field of currentIssueFieldValues) {
                if (formInstance && formInstance.id) {
                  if (!issueFormsMap.has(formInstance.id)) {
                    issueFormsMap.set(formInstance.id, {
                      formInstanceId: formInstance.id,
                      formFields    : []
                    })
                  }
                  issueFormsMap.get(formInstance.id).formFields.push({
                    formInstanceFieldId: formInstanceField.id,
                    value              : field.value
                  })
                } else {
                  if (!issueFormsMap.has(formField.formTemplateId)) {
                    issueFormsMap.set(formField.formTemplateId, {
                      formTemplateId: formField.formTemplateId,
                      formFields    : []
                    })
                  }
                  issueFormsMap.get(formField.formTemplateId).formFields.push({
                    formTemplateConfigurationId: formField.formTemplateConfigurationId,
                    value                      : field.value
                  })
                }
              }
            }
          }
          issueForms.push(...Array.from(issueFormsMap.values()))
        } else if(item.key === "labels"){
          const storedValues = this.screenItemValuesMap.get(item.key)

          if (!storedValues && item.value
            || storedValues && (item.value.length !== storedValues.length
            || item.value.some(label => !storedValues.includes(label)))
          ) {
            result[item.key] = item.value
          }

        } else {
          const storedValue = this.screenItemValuesMap.get(item.key)
          if (item.value !== storedValue) {
            result[item.key] = item.value
          }
        }
      }

      if (issueFieldValues.length) {
        result.issueFieldValues = issueFieldValues
      }
      if(issueForms.length) {
        result.issueForms = issueForms
      }

      this.$emit("submit", result)
    },
    updateIssueFieldValue(valueToUpdate, screenItem) {
      this.localScreenItems = this.localScreenItems.map(item => {
        if (item.key === screenItem.key && item.field.issueFieldId === screenItem.field.issueFieldId) {
          item.field.issueFieldValues = valueToUpdate
        }
        return item
      })
    },
    updateIssueFormFieldValue(valueToUpdate, screenItem) {
      this.localScreenItems = this.localScreenItems.map(item => {
        if (item.type === SCREEN_ITEM_TYPE.FORM) {
          item.formFields.map(formField => {
            if (formField.fieldId === screenItem.field.fieldId &&
              formField.formInstanceId === screenItem.field.formInstanceId) {
              formField.issueForms = [{ ...valueToUpdate[0], fieldId: screenItem.field.fieldId }]
            }
            return formField
          })
        }
        return item
      })
    },
    handleCancel() {
      this.$emit("cancel")
      this.showTextAreaCounter = false
      this.localScreenItems    = []
    },
    getScreenItemHint(screenItem) {
      if (screenItem.key === "domainId" && screenItem.value) {
        const dropDownItem = screenItem.items.find(item => item.value === screenItem.value)

        if (!dropDownItem.hasAccessToDomain) {
          return this.$t("1423")
        }
      }
    },
    handleTextAreaOnFocusEvent(onFocus) {
      onFocus()
      this.showTextAreaCounter = true
    },
    handleComboboxInputOnFocusEvent(onFocus) {
      onFocus()
      this.isComboboxInputFocussed = true
    },
    handleComboboxInputOnBlurEvent(onBlur) {
      onBlur()
      this.isComboboxInputFocussed = false
    },
    removeComboboxItem(labelToBeRemoved, localScreenItem) {
      this.localScreenItems = this.localScreenItems.filter(screenItem => {
        if (screenItem.key === localScreenItem.key) {
          screenItem.value = screenItem.value.filter(label => label !== labelToBeRemoved)
        }
        return screenItem
      })
    },
    formateDateTime(dateTime) {
      return dateTime ? this.$moment(dateTime).format("D MMMM YYYY HH:mm (UTCZ)") : null
    },
    getLocalScreenItem(formField, localScreenItem) {
      return {
        field: {
          formInstanceId             : formField.formInstanceId,
          fieldId                    : formField.fieldId,
          fieldLabel                 : formField.fieldLabel,
          fieldType                  : formField.fieldType,
          fieldWidget                : formField.fieldWidget,
          issueId                    : formField.issueId,
          issueFieldId               : formField.issueFieldId,
          issueFieldValues           : formField.issueFieldValues,
          formTemplateId             : formField.formTemplateId,
          formTemplateConfigurationId: formField.formTemplateConfigurationId,
          issueForms                 : formField.issueForms,
          isOptionListCascaded       : formField.isOptionListCascaded,
          optionListId               : formField.optionListId,
          optionListItems            : formField.optionListItems,
          readOnly                   : formField.readOnly
        },
        key           : formField.fieldId,
        mandatory     : formField.mandatory,
        screenItemType: localScreenItem.screenItemType
      }
    }
  },
  watch: {
    pScreenItems: {
      immediate: true,
      handler  : function(newValue) {
        for (const item of newValue) {
          if (item && item.type !== SCREEN_ITEM_TYPE.FORM) {
            const screenItem = {
              key           : item.key,
              mandatory     : item.mandatory,
              items         : item.items,
              type          : SCREEN_KEY_FIELD_TYPE[item.key],
              field         : item.field,
              screenItemType: item.type
            }
            if (SCREEN_KEY_FIELD_TYPE[item.key] === WIDGET.DATE_TIME_PICKER) {
              screenItem.showDateTimePickerDialog = false
            }
            if (item.key === "assigneeId") {
              screenItem.items = screenItem.items?.filter(item => item.name !== "Unassigned")
            }
            if (!item.field) {
              screenItem.value = item.value
            }

            if (item.type !== SCREEN_ITEM_TYPE.FIELD) {
              this.screenItemValuesMap.set(item.key, screenItem.value)
            } else {
              this.screenItemValuesMap.set(item.field.fieldId, screenItem.field.issueFieldValues)
            }
            this.localScreenItems.push(screenItem)
          } else {
            if (item && item.type === SCREEN_ITEM_TYPE.FORM) {
              item.formFields.map(formField => {
                this.screenItemValuesMap.set(formField.fieldId, formField.issueForms)
              })
            }
            this.localScreenItems.push(item)
          }
        }
      }
    },
    pIsTransitionDone: {
      handler: function(newValue) {
        if (newValue) {
          this.localScreenItems = []
        }
      }
    }
  }
}